<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">GPS Tracking: Elevating Fleet Management</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Explore how GPS tracking technology enhances efficiency, safety, and management in truck fleet operations.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">GPS Tracking in Fleet Operations</h3>
				<p class="mt-3 text-base leading-relaxed">GPS tracking technology is a cornerstone in modern truck fleet management. It offers real-time location data, enabling fleet managers to make informed decisions and optimize vehicle utilization. This technology significantly enhances operational efficiency by facilitating effective route planning and ensuring drivers adhere to the most efficient and safe routes. It also allows for quick response in emergencies, providing exact location details.<br><br>This technology extends beyond basic tracking; it's instrumental in fuel management by monitoring driving patterns and identifying opportunities to improve fuel efficiency. This includes reducing idling time and optimizing routes to avoid heavy traffic. By curbing fuel consumption, GPS tracking not only cuts operational costs but also minimizes environmental impact.<br><br>Additionally, GPS tracking plays a crucial role in enhancing driver safety. Fleet managers can monitor driving behaviors like speeding, harsh braking, and rapid acceleration, and implement corrective measures to promote safe driving practices. This not only reduces the risk of accidents but also aids in maintaining the health of fleet vehicles.<br><br>In summary, GPS tracking technology is vital for modern fleet management. It enhances operational efficiency, contributes to driver safety, and aids in cost reduction. With advancements in technology, GPS tracking is poised to become even more sophisticated, providing deeper insights and greater control over fleet operations.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="GPS Tracking in Fleet Operations" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Advanced Analytics & Reporting</h3>
					<p class="mt-3 text-base leading-relaxed">GPS tracking technology transcends basic location tracking by offering advanced analytics and reporting capabilities essential for strategic fleet management. These analytics provide deep insights into fleet operations, enabling managers to optimize performance and efficiency.<br><br>A key feature of GPS tracking is its ability to generate detailed reports on various aspects of fleet operations, such as distance traveled, time spent on routes, fuel consumption, and driver behavior. Analyzing this data helps fleet managers identify trends and patterns crucial for making informed decisions.<br><br>These reports aid in identifying inefficiencies in fleet operations and provide actionable insights for improvement. For instance, analyzing route data can reveal unnecessary detours or delays, while fuel consumption analysis can lead to identifying drivers who may need additional training in fuel-efficient driving practices.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Advanced Analytics & Reporting" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Advantages of GPS Tracking</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Discover the multitude of benefits GPS Tracking brings to truck fleet management, enhancing operations at every level.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Real-Time Vehicle Location</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Improved Route Efficiency</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Enhanced Driver Safety</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Reduced Operational Costs</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Accurate Fuel Monitoring</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Detailed Reporting Insights</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  