  <template>
    <div>
      <SiteHeader />
  <div class="bg-white py-6 sm:py-8 lg:py-12">
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
      <div class="mb-10 md:mb-16">
        <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Contact us</h2>
        <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">Have questions or suggestions? Want to place an order? Fill out our feedback form – we'll quickly get in touch to assist with all your inquiries!</p>
      </div>
      <form @submit.prevent="submitForm" class="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2">
        <div>
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">First name*</label>
          <input v-model="formData.firstName" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-lime-500 transition duration-100 focus:ring" />
        </div>
        <div>
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Last name*</label>
          <input v-model="formData.lastName" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-lime-500 transition duration-100 focus:ring" />
        </div>
        <div class="sm:col-span-2">
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Company</label>
          <input v-model="formData.company" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-lime-500 transition duration-100 focus:ring" />
        </div>
        <div class="sm:col-span-2">
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Email*</label>
          <input v-model="formData.email" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-lime-500 transition duration-100 focus:ring" />
        </div>
        <div class="sm:col-span-2">
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Subject*</label>
          <input v-model="formData.subject" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-lime-500 transition duration-100 focus:ring" />
        </div>
        <div class="sm:col-span-2">
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Message*</label>
          <textarea v-model="formData.message" class="h-64 w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none !ring-lime-500 transition duration-100 focus:ring"></textarea>
        </div>
        <div class="flex items-center justify-between sm:col-span-2">
          <button type="submit" class="inline-block rounded-lg bg-lime-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-lime-500 focus:border-lime-500 focus:ring-lime-500 transition duration-100 hover:bg-lime-600 focus-visible:ring active:bg-lime-600 md:text-base">Send</button>
          <p v-if="isSubmitted">Your message has been sent, we will contact you soon!</p>
          <span class="text-sm text-gray-500">*Required</span>
        </div>
      </form>
    </div>
  </div>
      </div>
    </template>
    
    <script>
    import SiteHeader from '@/components/SiteHeader.vue';
    
    export default {
      components: {
        SiteHeader,
      },
      data() {
      return {
        isModalVisible: false,
        isSubmitted: false,
        formData: {
          firstName: '',
          lastName: '',
          company: '',
          email: '',
          subject: '',
          message: '',
        },
      };
    },
    methods: {
      submitForm() {
      let formBody = new URLSearchParams(this.formData).toString();

      fetch('/contactsMailer.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody
      })
      .then(response => response.text())
      .then(data => {
          this.responseMessage = data;
          if (data.includes('Message sended')) {
              this.isSubmitted = true;
              this.formData = {
                firstName: '',
                lastName: '',
                company: '',
                email: '',
                subject: '',
                message: ''
              };
          }
      })
      .catch(error => {
          console.error('error:', error);
      });
  }
    }
    };
    </script>
    
    <style scoped>
  textarea{
    @apply border border-black/10
  }
    </style>
    