<template>
  <div>
    <SiteHeader />
    

<div class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="grid gap-4 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-8">
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/trends-in-truck-fleet-management-2023/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/trends-in-truck-fleet-management-2023.png" loading="lazy" alt="2023 Trends in Truck Fleet Management" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/trends-in-truck-fleet-management-2023/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">2023 Trends in Truck Fleet Management</a>
          </h2>
          <p class="text-gray-500">Are you keeping up with the evolving landscape of truck fleet management? Uncover the pivotal trends and innovations set to redefine the industry in 2023</p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/how-ai-redefining-fruck-fleet-management/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/ai.png" loading="lazy" alt="How Does AI Transform Truck Fleet Management" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/how-ai-redefining-fruck-fleet-management/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">How Does AI Transform Truck Fleet Management</a>
          </h2>
          <p class="text-gray-500">Ever wondered how AI is changing the game in truck fleet management? Unravel the 2023 trends that are setting new benchmarks in operational excellence and strategic foresight.</p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/best-strategies-for-reducing-fuel-costs/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/cutting-fleet-fuel-costs.png" loading="lazy" alt="Effective Strategies to Reduce Fleet Fuel Costs" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/best-strategies-for-reducing-fuel-costs/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">Effective Strategies to Reduce Fleet Fuel Costs</a>
          </h2>
          <p class="text-gray-500">Looking for ways to cut fuel expenses in your fleet? Uncover top strategies that can drive significant savings and enhance efficiency.</p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/importance-of-regular-driver-training/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/regular-drier-training.png" loading="lazy" alt="Regular Driver Training: Key to Fleet Safety Success?" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/importance-of-regular-driver-training/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">Regular Driver Training: Key to Fleet Safety Success?</a>
          </h2>
          <p class="text-gray-500">Curious about the role of driver training in fleet safety? Discover how consistent training can drastically improve fleet operations</p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/how-temperature-monitoring-improves-transport-sensitive-goods/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/temperature-monitoring.png" loading="lazy" alt="Temperature Monitoring: Essential for Sensitive Goods?" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/how-temperature-monitoring-improves-transport-sensitive-goods/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">Temperature Monitoring: Essential for Sensitive Goods?</a>
          </h2>
          <p class="text-gray-500">Curious about how temperature control can make or break the transport of sensitive goods? Dive into the role of this vital technology</p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/erp-and-crm-in-fleet-management/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/crm-and-erp.png" loading="lazy" alt="Transforming Fleet Management: Is ERP and CRM Integration the Key?" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/erp-and-crm-in-fleet-management/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">Transforming Fleet Management: Is ERP and CRM Integration the Key?</a>
          </h2>
          <p class="text-gray-500">Curious about integrating ERP and CRM with fleet management? Unravel how this integration can redefine operational efficiency and customer relations</p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/advantages-of-gps-tracking-in-fleet-management/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/gps-tracking.jpg" loading="lazy" alt="GPS Tracking in Fleet Management: A Game Changer?" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/advantages-of-gps-tracking-in-fleet-management/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">GPS Tracking in Fleet Management: A Game Changer?</a>
          </h2>
          <p class="text-gray-500">Ever wondered how GPS tracking is revolutionizing fleet management? Dive into its benefits for improving operational efficiency and safety</p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/analyzing-total-cost-of-ownership-in-fleet-management/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/fleet-costs.png" loading="lazy" alt="Mastering Fleet Costs: Total Cost of Ownership Insights" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/analyzing-total-cost-of-ownership-in-fleet-management/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">Mastering Fleet Costs: Total Cost of Ownership Insights</a>
          </h2>
          <p class="text-gray-500">Thinking of ways to cut fleet expenses? Discover how analyzing total cost of ownership can lead to more effective and economical fleet management</p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg border bg-white">
        <a href="/blog/role-of-iot-in-modern-fleet-management/" class="group relative block h-48 overflow-hidden bg-gray-100 md:h-64">
          <img src="/images/blog/IOT.png" loading="lazy" alt="IoT: Revolutionizing Fleet Management Today?" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
        </a>
        <div class="flex flex-1 flex-col p-4 sm:p-6">
          <h2 class="mb-2 text-lg font-semibold text-gray-800">
            <a href="/blog/role-of-iot-in-modern-fleet-management/" class="transition duration-100 hover:text-lime-500 active:text-lime-500">IoT: Revolutionizing Fleet Management Today?</a>
          </h2>
          <p class="text-gray-500">Curious about IoT's impact on fleet management? Dive into how IoT technology is changing the face of modern fleet operations and logistics</p>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  