<template>
  <div>
    <SiteHeader />
    <ProductCard />
  </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  import ProductCard from '@/components/ProductCard.vue';
  export default {
    components: {
      SiteHeader,
      ProductCard,
    },
  };
  </script>
  
  <style scoped>
  </style>
  