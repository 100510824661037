<template>
    <div>
    <div class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-lg px-4 md:px-8">
    <div class="grid gap-8 md:grid-cols-2">
      <div class="space-y-4">
        <div class="relative overflow-hidden rounded-lg bg-gray-100">
          <img :src="productImage" loading="lazy" :alt="productTitle" class="h-full w-full object-cover object-center"/>
          <span class="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">sale</span>
        </div>
      </div>
      <div>
        <div class="mb-2 md:mb-3">
          <h2 class="text-2xl font-bold text-gray-800 lg:text-3xl">{{ productTitle }}</h2>
        </div>
        <div class="mb-4 flex items-center">
          <div class="-ml-1 flex gap-0.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>
          <span class="ml-2 text-sm text-gray-500">{{ productRating }}</span>
        </div>
        <div class="mb-4">
          <div class="flex items-end gap-2">
            <span class="text-xl font-bold text-gray-800 md:text-2xl">{{ productPrice }}</span>
            <span class="mb-0.5 text-red-500 line-through">{{ productDiscount }}</span>
          </div>
          <span class="text-sm text-gray-500">incl. VAT plus shipping</span>
        </div>
        <div class="mb-6 flex items-center gap-2 text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"/>
          </svg>
          <span class="text-sm">2-3 day shipping</span>
        </div>
        <div class="flex gap-2.5">
          <button class="inline-block rounded-lg bg-lime-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-lime-600 focus-visible:ring active:bg-lime-600 md:text-base" @click="order">Order</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mb-4 border-b border-gray-200 mx-auto max-w-screen-lg px-4 md:px-8">
    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500" id="product-tabs" role="tablist">
        <li class="me-2" role="presentation">
            <button class="inline-block rounded-t-lg border-b-2 border-transparent p-4 hover:border-gray-300 hover:text-gray-600" id="description-tab" type="button" role="tab" aria-controls="description" aria-selected="false">Description</button>
        </li>
        <li class="me-2" role="presentation">
            <button class="inline-block rounded-t-lg border-b-2 border-transparent p-4 hover:border-gray-300 hover:text-gray-600" id="delivery-tab" type="button" role="tab" aria-controls="delivery" aria-selected="false">Delivery information</button>
          </li>
        <li role="presentation">
            <button class="inline-block rounded-t-lg border-b-2 border-transparent p-4 hover:border-gray-300 hover:text-gray-600" id="contacts-tab" type="button" role="tab" aria-controls="contacts" aria-selected="false">Contacts</button>
        </li>
    </ul>
</div>
<div class="mx-auto max-w-screen-lg px-4 md:px-8">
    <div class="hidden rounded-lg bg-gray-50 p-4" id="description" role="tabpanel" aria-labelledby="description-tab">
<p class="text-sm text-gray-500" v-html="productDescription"></p>
    </div>
    <div class="hidden rounded-lg bg-gray-50 p-4 text-sm text-gray-500" id="delivery" role="tabpanel" aria-labelledby="delivery-tab">
        <h3 class="mb-2 font-bold">Shipping Destinations:</h3>
        <p class="mb-4">We currently offer delivery services for our products exclusively within the United States. Whether you are in a bustling urban center or a remote location, our delivery network is designed to reach you efficiently.</p>
        <h3 class="mb-2 font-bold">Delivery Options:</h3>
        <p class="mb-4">We understand that our customers have diverse preferences when it comes to receiving their orders. To cater to your needs, we provide a range of delivery options, each designed to offer flexibility and convenience. Choose the option that best aligns with your schedule and requirements during the checkout process.</p>
        <h3 class="mb-2 font-bold">Estimated Delivery Times:</h3>
        <p class="mb-4">Our commitment to timely deliveries is a top priority. Once your order is confirmed, we provide estimated delivery times based on your location and the selected shipping option. These estimates are transparently communicated during the checkout process, allowing you to plan accordingly.</p>
        <h3 class="mb-2 font-bold">Shipping Costs:</h3>
        <p class="mb-4">To maintain transparency and fairness, our shipping costs are calculated based on various factors, including the delivery destination and the chosen shipping option. You will find a detailed breakdown of shipping costs before finalizing your purchase, ensuring there are no surprises.</p>
        <h3 class="mb-2 font-bold">Order Tracking:</h3>
        <p class="mb-4">Stay informed about the status of your order with our user-friendly order tracking system. As soon as your order is dispatched, you will receive tracking information, enabling you to monitor its journey until it reaches your doorstep.</p>
        <h3 class="mb-2 font-bold">Customer Support:</h3>
        <p class="mb-4">Should you have any inquiries or require assistance regarding your delivery, our customer support team is ready to assist you. Contact us through the provided channels, and we will promptly address any questions or concerns you may have.</p>
    </div>
    <div class="hidden rounded-lg bg-gray-50 p-4" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">If you have any questions or would like to get in touch with us, please write to us at <a class="text-lime-500" href="mailto:shop@roadeld.com">shop@roadeld.com</a> or fill out the form on the <a class="text-lime-500" href="/contacts/">contact page</a>.</div>
</div>
<div v-if="isModalVisible" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed inset-0 z-50 justify-center items-center">
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
        <div class="relative p-4 bg-white rounded-lg drop-shadow-2xl sm:p-5">
            <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5">
                <h3 class="text-lg font-semibold text-gray-900">Order {{ productTitle }}</h3>
                <button @click="closeModal" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="updateProductModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <form @submit.prevent="submitForm" v-if="!isSubmitted">
                <div class="grid gap-4 mb-4 sm:grid-cols-2">
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Name</label>
                        <input type="text" v-model="formData.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5" placeholder="Enter your name" required>
                    </div>
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                        <input type="text" v-model="formData.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5" placeholder="Enter your Email" required>
                    </div>
                    <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Quantity</label>
                        <input type="number" v-model="formData.quantity" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5" required>
                    </div>
                    <div>
                      <div>
                        <label class="block mb-2 text-sm font-medium text-gray-900">Phone number (Optional)</label>
                        <input type="text" v-model="formData.phone" @input="validatePhone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5">
                    </div>
                    </div>
                </div>
                <div class="flex items-center space-x-4">
                  <button type="submit" class="inline-block rounded-lg bg-lime-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none transition duration-100 hover:bg-lime-600 focus-visible:ring active:bg-lime-600 md:text-base">Send</button>
                </div>
            </form>
            <div v-else class="flex flex-col">
              <p>Your order has been accepted, and we will contact you shortly to clarify the details! You can close this window and continue using the site.</p>
              <button @click="closeModalButton2" type="button" class="inline-block rounded-lg bg-lime-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-lime-600 focus-visible:ring active:bg-lime-600 md:text-base mt-7">Close</button>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import { Tabs } from 'flowbite';
export default {
  computed: {
    productImage() {
      return this.$route.meta.productImage || 'Default Heading';
    },
    productPrice() {
      return this.$route.meta.productPrice || '100$';
    },
    productDescription() {
      return this.$route.meta.productDescription || 'Product Description';
    },
    productTitle() {
      return this.$route.meta.productTitle || 'Product Title';
    },
    productDiscount() {
      return this.$route.meta.productDiscount || 'Product Discount';
    },
    productRating() {
      return this.$route.meta.productRating || 'Product Rating';
    },
  },
  mounted() {
    const tabsElement = document.getElementById('product-tabs');
    const tabElements = [
      {
        id: 'description',
        triggerEl: document.querySelector('#description-tab'),
        targetEl: document.querySelector('#description'),
      },
      {
        id: 'delivery',
        triggerEl: document.querySelector('#delivery-tab'),
        targetEl: document.querySelector('#delivery'),
      },
      {
        id: 'contacts',
        triggerEl: document.querySelector('#contacts-tab'),
        targetEl: document.querySelector('#contacts'),
      },
    ];

    const options = {
      defaultTabId: 'description',
      activeClasses: 'bg-black/5 font-bold text-lime-500 hover:text-lime-600 border-lime-500',
      inactiveClasses: 'text-gray-500 hover:text-gray-600 border-gray-100 hover:border-lime-500',
    };

    const tabs = new Tabs(tabsElement, tabElements, options);

    tabs.show('description');
    tabs.getTab('contacts');
    tabs.getActiveTab();
  },
  data() {
    return {
      isModalVisible: false,
      isSubmitted: false,
      formData: {
        name: '',
        email: '',
        quantity: '1',
        phone: '',
        responseMessage: '',
        product: '',
      },
    };
  },
  methods: {
    validatePhone(){
      this.formData.phone = this.formData.phone.replace(/[^\d +]/g, '');
    },
    order() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeModalButton2() {
      this.isModalVisible = false;
    },
    submitForm() {
      this.formData.productTitle = this.productTitle;
    let formBody = new URLSearchParams(this.formData).toString();
    fetch('/mailer.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody
    })
    .then(response => response.text())
    .then(data => {
        this.responseMessage = data;
        if (data.includes('Message sended')) {
            this.isSubmitted = true;
            this.formData = {
                name: '',
                email: '',
                quantity: '',
                phone: ''
            };
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
}
  }
};
</script>


<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>