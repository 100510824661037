<template>
  <div>
    <SiteHeader />
    <section class="bg-white">
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
          <h2 class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">Our Solutions</h2>
          <p class="font-light text-gray-500 sm:text-xl">Explore solutions what we provide</p>
      </div> 
      <div class="grid gap-8 lg:grid-cols-2">
          <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/eld-solution/">ELD Solutions</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for electronic logging of driver hours and compliance with safety standards. They allow you to easily and quickly track and transmit data on driver work, as well as avoid fines and inspections.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/eld-solution/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div> 
          <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/gps-tracking/">GPS Tracking</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for monitoring the location and movement of your vehicles in real time. They help you increase efficiency and fuel savings, as well as protect your property from theft and loss.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/gps-tracking/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div>    
            <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/fleet-management-software/">Fleet Management Software</a></h3>
              <p class="mb-5 font-light text-gray-500">This is software for managing your fleet and drivers. It allows you to optimize routes and schedule, control costs and revenues, analyze performance and quality of work, as well as improve customer service.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/fleet-management-software/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div>         
            <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/dash-camera/">Dash Cams and Safety Solutions</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for enhancing the safety of your vehicles and drivers. They include dash cams that record video and audio from the road and cabin, as well as driver assistance and warning systems that help prevent accidents and violations.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/dash-camera/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div> 
            <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/tail-light-gps-tracker/">Tail Light GPS Trackers</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for covert tracking of the location of your vehicles. They are GPS trackers that are embedded in the tail lights and are not visible to outsiders. They allow you to always know where your vehicles are, as well as receive alerts about any suspicious actions.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/tail-light-gps-tracker/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div> 
            <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/maintenance-diagnostics/">Maintenance and Diagnostics</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for maintenance and diagnostics of your vehicles. They allow you to connect to the onboard computer and get information about the condition and faults of your vehicles, as well as remind you of the need for maintenance and repair.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/maintenance-diagnostics/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div> 
            <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/driver-trainning/">Driver Training and Support</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for training and support of your drivers. They allow you to evaluate and improve the driving skills of your drivers, as well as provide them with access to training materials and tips on safety and fuel economy.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/driver-trainning/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div> 
            <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/fuel-management/">Fuel Management Solutions</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for managing fuel consumption in your fleet. They allow you to track and analyze the fuel consumption of your vehicles, as well as find ways to reduce costs and emissions.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/fuel-management/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div> 
            <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/temperature-monitoring/">Temperature Monitoring Solutions</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for controlling the temperature in your vehicles, especially if you transport perishable or temperature-sensitive goods. They allow you to measure and regulate the temperature in the body or trailer, as well as receive alerts about any deviations or violations.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/temperature-monitoring/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div> 
            <div class="p-6 bg-white rounded-lg border border-lime-500 shadow-md">
              <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900"><a href="/solutions/custom-integrations/">Custom Integration Solutions</a></h3>
              <p class="mb-5 font-light text-gray-500">These are solutions for integrating your existing systems and applications with our solutions. They allow you to combine all your data and functions in one place, as well as customize and adapt our solutions to your individual needs and preferences.</p>
              <div class="flex justify-between items-center">
                  <a href="/solutions/custom-integrations/" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
            </div> 
      </div>  
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  