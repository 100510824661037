<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Optimize Your Fleet with Fuel Management</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Maximize efficiency and reduce costs with our state-of-the-art fuel management solutions for your truck fleet.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">Efficient Fuel Usage Tracking</h3>
				<p class="mt-3 text-base leading-relaxed">Effective fuel management is crucial in truck fleet operations, impacting both the economic and environmental aspects of the business. Our Fuel Management Solutions are designed to provide comprehensive insights into fuel usage, enabling fleets to optimize consumption and reduce unnecessary costs. By integrating advanced sensors and telematics, our system accurately monitors and records fuel levels, consumption rates, and refueling activities for each vehicle in the fleet.<br><br>This granular data allows fleet managers to identify patterns and anomalies in fuel usage, such as excessive idling or inefficient routes, which can lead to significant waste. Our system not only tracks fuel metrics but also correlates them with driving behavior, vehicle condition, and operational routes, offering a holistic view of how fuel consumption is impacted by various factors.<br><br>Moreover, our fuel management solutions include customizable alerts and reports. Managers can set thresholds for fuel consumption and receive instant notifications when these limits are exceeded, facilitating prompt action to address inefficiencies. Regularly generated reports provide valuable insights for long-term planning, highlighting opportunities for improvement and helping in budget forecasting.<br><br>In addition to monitoring, our system aids in compliance with environmental regulations, ensuring that your fleet adheres to emission standards. This not only reduces the environmental footprint of your fleet but also safeguards against potential fines and legal issues.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="Efficient Fuel Usage Tracking" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Strategic Fuel Cost Reduction</h3>
					<p class="mt-3 text-base leading-relaxed">Our Fuel Management Solutions go beyond mere tracking; they empower fleets with strategic tools to reduce fuel costs effectively. By leveraging the detailed data provided by our system, fleet managers can implement targeted strategies to optimize fuel usage. This includes route optimization, where the most fuel-efficient routes are identified and utilized, and driver training programs focused on fuel-saving driving techniques.<br><br>Additionally, our system supports predictive maintenance scheduling. By maintaining vehicles in optimal condition, fuel efficiency is maximized, preventing the extra consumption caused by underperforming engines or other mechanical issues.<br><br>We also provide integration capabilities with fuel card programs, enabling more controlled and transparent fuel transactions. This integration helps in preventing fuel fraud and unauthorized use, further contributing to cost savings.<br><br>Furthermore, our solutions offer scalability and customization. Regardless of the size of your fleet or the specific challenges you face, our system can be tailored to meet your unique needs, ensuring that you achieve the maximum benefit from your fuel management strategy.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Strategic Fuel Cost Reduction" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Benefits of Advanced Fuel Management</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Discover how our specialized fuel management solutions can transform your fleet's efficiency and profitability.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Optimized Fuel Consumption Tracking</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Reduction in Idle Times</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Accurate Fuel Theft Detection</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Enhanced Driver Fuel Efficiency</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Effective Fuel Purchase Management</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Eco-Friendly Fleet Operations</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  