<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Integrating ELD in Fleets</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Understanding the role and impact of Electronic Logging Devices in modern fleet management.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">ELDs for Compliance & Safety</h3>
				<p class="mt-3 text-base leading-relaxed">Electronic Logging Devices (ELDs) have become a fundamental aspect of fleet management, primarily due to their role in ensuring compliance with Hours of Service (HoS) regulations. These devices automate the recording of driving time, vehicle movement, and precise location, providing an irrefutable and tamper-resistant record. This level of automation is crucial in minimizing the risk of human error and potential HoS violations, which can result in significant fines and legal consequences.<br><br>Furthermore, ELDs play a vital role in enhancing road safety. They ensure that drivers adhere to mandated driving hours, thereby significantly reducing the risk of accidents caused by driver fatigue. This is particularly crucial in industries where drivers are prone to long hours on the road. With ELDs, fleet managers have real-time access to data, allowing for effective monitoring and enforcement of safe driving practices.<br><br>The implementation of ELDs represents a significant advancement in the field of fleet management. It marks a shift from manual tracking methods, which were often unreliable and inefficient, to a more streamlined, accurate, and reliable system. This shift not only aids in regulatory compliance but also fosters a safer working environment for drivers. By ensuring that drivers are not overworked and are operating within legal limits, ELDs contribute to a more responsible and ethical approach to fleet management.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="ELDs for Compliance & Safety" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Optimizing with ELD Data</h3>
					<p class="mt-3 text-base leading-relaxed">Beyond regulatory compliance, ELDs are instrumental in optimizing fleet operations. These devices provide a wealth of data that is vital for informed decision-making. This includes detailed reports on driving patterns, fuel usage, and idle times - all of which are pivotal in identifying operational inefficiencies and areas for improvement.<br><br>Route optimization is a key benefit derived from ELD data. By tracking vehicle movements, ELDs aid in planning the most efficient routes. This not only reduces fuel consumption but also saves considerable time, leading to enhanced operational efficiency and cost savings. Such optimization is not only beneficial in terms of operational costs but also reduces the environmental impact of the fleet.<br><br>Moreover, the data from ELDs can be leveraged for predictive maintenance. This proactive approach involves scheduling servicing and maintenance activities before potential issues escalate into major problems, thereby minimizing vehicle downtime and extending the lifespan of fleet vehicles.<br><br>The integration of ELDs into fleet management systems signifies a move towards more data-driven, efficient, and safe fleet operations. It marks a paradigm shift in how fleet operations are managed, positioning businesses for improved scalability and profitability. In an era where efficiency and compliance are paramount, ELDs offer the tools necessary to achieve these objectives, ensuring that fleet operations are not only compliant with regulations but also optimized for peak performance.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Optimizing with ELD Data" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Advantages of Implementing ELD Solutions</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Discover the top benefits of ELD Solutions that contribute to efficient and compliant fleet management.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Automated HoS Compliance</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Enhanced Driver Safety</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Real-Time Fleet Tracking</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Reduced Paperwork</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Data for Better Decisions</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Maintenance Alerts</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  