<template>
  <div>
    <SiteHeader />
<div class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-md px-4 md:px-8">
    <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6">{{FirstH2}}</h2>
    <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
      {{ text1 }}
      <br><br>
      {{text2}}
    </p>
    <h2 class="mb-2 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">{{SecondH2}}</h2>
    <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">{{text3}}</p>
    <ul class="mb-6 list-inside list-disc text-gray-500 sm:text-lg md:mb-8">
      <li>{{list1}}</li>
      <li>{{list2}}</li>
      <li>{{list3}}</li>
      <li>{{list4}}</li>
    </ul>
    <div class="relative mb-6 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8">
      <img :src="articleImage" loading="lazy" :alt="altImage" class="h-full w-full object-cover object-center" />
    </div>
    <h2 class="mb-2 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">{{ThirdH2}}</h2>
    <p class="text-gray-500 sm:text-lg">{{text4}}</p>
  </div>
</div>
  </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  export default {
    components: {
      SiteHeader,
    },
    computed: {
      productTitle() {
      return this.$route.meta.productTitle || 'Product Title';
    },
    productDescription() {
      return this.$route.meta.productDescription || 'Product Description';
    },
    FirstH2() {
      return this.$route.meta.FirstH2 || 'FirstH2';
    },
    text1() {
      return this.$route.meta.text1 || 'text1';
    },
    text2() {
      return this.$route.meta.text2 || 'text2';
    },
    SecondH2() {
      return this.$route.meta.SecondH2 || 'SecondH2';
    },
    text3() {
      return this.$route.meta.text3 || 'text3';
    },
    list1() {
      return this.$route.meta.list1 || 'list1';
    },
    list2() {
      return this.$route.meta.list2 || 'list2';
    },
    list3() {
      return this.$route.meta.list3 || 'list3';
    },
    list4() {
      return this.$route.meta.list4 || 'list4';
    },
    ThirdH2() {
      return this.$route.meta.ThirdH2 || 'ThirdH2';
    },
    text4() {
      return this.$route.meta.text4 || 'text4';
    },
    articleImage() {
      return this.$route.meta.articleImage || 'Image';
    },
    altImage() {
      return this.$route.meta.altImage || 'Article image';
    },
  },
  };
  </script>
  
  <style scoped>
  </style>
  