<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Next-Gen Fleet Management Software</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Explore cutting-edge software features designed to revolutionize your truck fleet operations.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">Integration Capabilities</h3>
				<p class="mt-3 text-base leading-relaxed">Our Fleet Management Software represents a paradigm shift in how truck fleets are managed. Designed with the modern logistics and transportation industry in mind, our software brings unparalleled ease and efficiency to fleet operations. It seamlessly integrates with existing systems, providing a unified platform for tracking, management, and analysis.<br><br>The core of our software lies in its advanced GPS tracking system, offering real-time location data for each vehicle in your fleet. This feature is crucial for optimizing routes, reducing fuel consumption, and enhancing delivery times. Furthermore, our software includes an intuitive scheduling tool, simplifying the task of dispatching and ensuring that the right vehicle is always on the right task.<br><br>Another significant aspect is the predictive maintenance module. By analyzing vehicle data and usage patterns, the software can predict potential issues before they arise, minimizing downtime and maintenance costs. The system also keeps track of maintenance schedules, ensuring that each vehicle is serviced at the optimal time.<br><br>In addition to these features, our software offers detailed reporting and analytics. These tools provide insights into various aspects of your fleet's performance, such as fuel usage, driver behavior, and vehicle efficiency. With customizable reports, you can focus on the metrics that matter most to your business, aiding in strategic decision-making and continuous improvement.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="Integration Capabilities" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Enhanced Security & Compliance</h3>
					<p class="mt-3 text-base leading-relaxed">Security and compliance are paramount in fleet management. Our software addresses these concerns head-on with robust security features and compliance tools. With data encryption and secure cloud storage, sensitive information about your fleet and operations remains protected against cyber threats.<br><br>The compliance module of our software is particularly noteworthy. It ensures that your fleet adheres to local and international transportation regulations, reducing the risk of costly fines and legal issues. This includes monitoring drivers' hours of service, vehicle load capacities, and necessary certifications. The system automatically alerts you to any potential compliance issues, allowing for prompt corrective action.<br><br>
Driver safety is another critical focus of our software. It includes features like driver behavior monitoring, which tracks and analyzes driving patterns to identify risky behaviors. This information can be used for targeted training and interventions, promoting a culture of safety and responsibility within your fleet.<br><br>
Additionally, our software facilitates better communication between fleet managers and drivers. With features like in-app messaging and notifications, important information can be relayed instantly, improving coordination and responsiveness.<br><br>
In summary, our Fleet Management Software provides a comprehensive, secure, and compliant solution for managing your truck fleet. It's not just about tracking and maintenance; it's about transforming your entire fleet operation into a more efficient, safe, and compliant enterprise.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Enhanced Security & Compliance" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Benefits of Our Fleet Software</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Explore the top advantages of our software, designed to optimize your truck fleet's performance and safety.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Real-Time Vehicle Tracking</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Automated Maintenance Alerts</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Cost-Effective Route Planning</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Data-Driven Decision Making</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Compliance with Safety Standards</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Enhanced Driver Safety Monitoring</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  