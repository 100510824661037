<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Tailored Integration Solutions for Fleets</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Elevate your fleet management with custom integration solutions designed to streamline operations and enhance efficiency.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">Bespoke System Integration</h3>
				<p class="mt-3 text-base leading-relaxed">In the dynamic field of truck fleet management, the ability to integrate various technological systems is essential for operational efficiency. Our Custom Integration Solutions are designed to seamlessly blend your existing fleet management systems with new, innovative technologies. We understand that each fleet has unique needs and challenges, which is why we offer bespoke integration services tailored to your specific requirements.<br><br>Our integration process begins with a thorough analysis of your current systems and operational workflows. This allows us to identify key areas where integration can bring the most value. We then design a custom solution that not only integrates with your existing systems but also enhances them. This might include integrating GPS tracking systems with fuel management software, or syncing vehicle maintenance logs with real-time diagnostic data.<br><br>The result is a unified, efficient, and easy-to-use platform that provides a comprehensive overview of your fleet operations. Our integrations enable you to access all necessary information from a single dashboard, improving decision-making and operational transparency. We also ensure that the integration is scalable, allowing for future expansions or adjustments as your fleet grows or your needs change.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="Bespoke System Integration" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Streamlined Operations & Data Analysis</h3>
					<p class="mt-3 text-base leading-relaxed">The advantages of our Custom Integration Solutions extend beyond just system compatibility. By streamlining your operations, we help you unlock greater efficiencies across your fleet. Our integrations enable automated data collection and analysis, reducing manual input errors and saving valuable time. This automated process provides accurate and up-to-date information, allowing for more precise tracking of vehicle performance, fuel usage, and driver behavior.<br><br>Our solutions also offer advanced analytics capabilities. By aggregating data from various sources, we provide deep insights into your fleet operations, helping you to identify trends, optimize routes, and improve overall efficiency. This data-driven approach can lead to significant cost savings, reduced environmental impact, and enhanced service quality.<br><br>Moreover, our integrations facilitate better communication and coordination among different departments. Maintenance schedules can be automatically updated based on real-time vehicle data, dispatching can be optimized based on vehicle locations, and compliance reporting becomes more streamlined.<br><br>By choosing our Custom Integration Solutions, you're not just upgrading your technology; you're transforming the way your fleet operates, leading to a more efficient, proactive, and data-driven approach to fleet management.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Streamlined Operations & Data Analysis" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Benefits Of Custom Integrations</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Unleash the full potential of your fleet with our customized solutions, designed for maximum efficiency and innovation.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Solutions Tailored to Specific Needs</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Enhanced Operational Flexibility</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Scalable with Fleet Growth</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Cutting-Edge Technological Integration</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Data-Driven Insights for Improvement</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Continuous Support and Evolution</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  