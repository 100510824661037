<template>
  <div>
    <SiteHeader />
    <section class="bg-white">
    <div class="gap-8 py-8 px-4 mx-auto max-w-screen-xl md:flex md:grid-cols-2 sm:py-16 lg:px-6">
        <img class="w-full min-w-[400px]" src="/images/appstore.png" alt="App store icon">
        <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Eld App for iPhone: Manage your logs easily and conveniently</h2>
            <p class="mb-6 font-light text-gray-500 md:text-lg">Eld App for iPhone is an application that helps you manage your logs in one place. Download Eld App for iPhone and get access to a multitude of features that will help you manage your logs. Eld App for iPhone allows you to easily and quickly create new logs, edit existing ones, as well as view and share them with others. Eld App for iPhone also provides you with access to many other features such as violation notifications, trip reports, and much more. Download Eld App for iPhone today and start managing your logs easily and conveniently.</p>
            <a href="https://apps.apple.com/" class="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-lime-500">
                Download in App Store
                <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
        </div>
    </div>
</section>
<section class="bg-white">
    <div class="gap-8 py-8 px-4 mx-auto max-w-screen-xl md:flex md:grid-cols-2 sm:py-8 lg:px-6">
        <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Eld app for Android</h2>
            <p class="mb-6 font-light text-gray-500 md:text-lg">Eld App for Android is an application that helps you manage your logs in one place. With Eld App for Android, you can easily and quickly create new logs, edit existing ones, as well as view and share them with others. Eld App for Android also provides you with access to many other features such as violation notifications, trip reports, and much more. Eld App for Android is designed to be user-friendly and intuitive, making it easy for you to manage your logs on the go. To get started, simply download Eld App for Android from the Google Play Store and follow the instructions to set up your account. Once you have created your account, you can start using Eld App for Android to manage your logs easily and conveniently.</p>
            <a href="https://play.google.com/" class="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-lime-500">
                Download in Google Play
                <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
        </div>
        <img class="w-full min-w-[400px]" src="/images/gplay.png" alt="Google play icon">
    </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  