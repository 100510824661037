<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Advanced Fleet Maintenance & Diagnostics</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Explore our comprehensive solutions for maintaining and diagnosing your fleet, ensuring optimal performance and longevity.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">Proactive Maintenance Tools</h3>
				<p class="mt-3 text-base leading-relaxed">In the dynamic world of truck fleet management, proactive maintenance and accurate diagnostics are key to ensuring the longevity and efficiency of your fleet. Our Maintenance and Diagnostics solutions provide an all-encompassing approach to vehicle care. By leveraging advanced diagnostic technology, our system can predict and prevent potential vehicle issues before they escalate into costly repairs or unscheduled downtime.<br><br>Our software continuously monitors vehicle health, collecting data on various parameters such as engine performance, fluid levels, and wear-and-tear of critical components. This real-time monitoring allows for immediate detection of any anomalies, triggering alerts for fleet managers. With this information, you can schedule maintenance activities efficiently, avoiding the disruption of unexpected breakdowns.<br><br>The diagnostic capabilities of our solution extend beyond simple fault detection. They provide a deep analysis of the root causes of issues, guiding technicians in performing precise and effective repairs. This not only saves time and resources but also extends the life of your vehicles.<br><br>Moreover, our system is designed to adapt to the unique needs of your fleet. Whether you operate a small number of specialized vehicles or a large, diverse fleet, our Maintenance and Diagnostics solutions can be customized to fit your specific requirements, ensuring you always have the insights and tools needed to maintain your fleet at peak performance.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="Proactive Maintenance Tools" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Data-Driven Maintenance Planning</h3>
					<p class="mt-3 text-base leading-relaxed">The strength of our Maintenance and Diagnostics solutions lies in their ability to facilitate data-driven decision-making. Utilizing the wealth of data collected from your fleet, our software enables you to plan and optimize maintenance schedules based on actual vehicle usage and condition, rather than relying on generic intervals. This approach not only reduces maintenance costs but also maximizes vehicle uptime and productivity.<br><br>Our system's advanced analytics capabilities allow you to identify trends and patterns in vehicle performance, highlighting areas that may require attention. This proactive approach to maintenance planning ensures that your vehicles are always in optimal condition, reducing the likelihood of breakdowns and extending their operational lifespan.<br><br>Additionally, our software provides detailed maintenance logs and reports, offering complete transparency and traceability of all maintenance activities. This documentation is crucial for regulatory compliance and can be invaluable during audits or warranty claims.<br><br>Furthermore, our Maintenance and Diagnostics solutions are designed with the future in mind. As technology evolves and new types of vehicles enter the market, our software can easily be updated to accommodate these changes, ensuring that your fleet remains at the forefront of maintenance and diagnostic technology.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Data-Driven Maintenance Planning" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Benefits of Our Maintenance Solutions</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Maximize your fleet's efficiency and reliability with our tailored maintenance and diagnostic solutions.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Automated Fault Detection</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Custom Maintenance Protocols</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">In-Depth Vehicle Health Analytics</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Efficiency in Repairs and Servicing</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Long-Term Cost Savings</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Extended Vehicle Life Span</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  