<template>
    <header>
<nav class="bg-black border-gray-200">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img src="/images/logo.png" class="h-8" alt="Flowbite Logo"/>
        <span class="self-center text-2xl font-semibold whitespace-nowrap">Flowbite</span>
    </a>
    <button data-collapse-toggle="navbar-multi-level" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-lime-500 rounded-lg md:hidden hover:bg-neutral-800 focus:outline-none focus:ring-2 focus:ring-lime-500" aria-controls="navbar-multi-level" aria-expanded="false">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-multi-level">
      <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-lime-500 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 bg-black">
        <li>
          <a href="/" class="block py-2 px-3 text-white bg-lime-500 rounded md:bg-transparent md:p-0 hover:text-lime-500" aria-current="page" :class="{ 'active': $route.path === '/' }">Home</a>
        </li>
        <li>
            <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" class="flex items-center justify-between w-full py-2 px-3 text-white hover:bg-neutral-800 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0 md:w-auto" :class="{ 'active': isShopActive}">Shop <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
  </svg></button>
            <div id="dropdownNavbar" class="z-10 hidden font-normal bg-neutral-800 divide-y divide-gray-100 rounded-lg shadow w-44 border border-lime-500">
                <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownLargeButton">
                  <li>
                    <a href="/eld-device/" class="block px-4 py-2 bg-neutral-800 text-white hover:text-lime-500" :class="{ 'active': $route.path === '/eld-device/' }">ELD Device</a>
                  </li>
                  <li aria-labelledby="dropdownNavbarLink">
                    <button id="doubleDropdownButton" data-dropdown-toggle="doubleDropdown" data-dropdown-placement="right-start" type="button" class="flex items-center justify-between w-full px-4 py-2 bg-neutral-800 text-white hover:text-lime-500" :class="{ 'active': isConnectorActive}">Connectors<svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
  </svg></button>
                    <div id="doubleDropdown" class="z-10 hidden bg-neutral-800 divide-y divide-gray-100 rounded-lg shadow w-44 border border-lime-500">
                        <ul class="py-2 text-sm text-gray-700" aria-labelledby="doubleDropdownButton">
                          <li>
                            <a href="/9pin-cable/" class="block px-4 py-2 hover:bg-neutral-800 text-white hover:text-lime-500" :class="{ 'active': $route.path === '/9pin-cable/' }">9-PIN Connector</a>
                          </li>
                          <li>
                            <a href="/6pin-cable/" class="block px-4 py-2 hover:bg-neutral-800 text-white hover:text-lime-500" :class="{ 'active': $route.path === '/6pin-cable/' }">6-PIN Connector</a>
                          </li>
                          <li>
                            <a href="/obdii-connector/" class="block px-4 py-2 hover:bg-neutral-800 text-white hover:text-lime-500" :class="{ 'active': $route.path === '/obdii-connector/' }">OBDII Connector</a>
                          </li>
                        </ul>
                    </div>
                  </li>
                  <li>
                    <a href="/dash-camera/" class="block px-4 py-2 hover:bg-neutral-800 text-white hover:text-lime-500" :class="{ 'active': $route.path === '/dash-camera/' }">Dash cam</a>
                  </li>
                  <li>
                    <a href="/gps-tracker/" class="block px-4 py-2 hover:bg-neutral-800 text-white hover:text-lime-500" :class="{ 'active': $route.path === '/gps-stacker/' }">GPS Tracker</a>
                  </li>
                </ul>
            </div>
        </li>
        <li>
          <a href="/pricing/" class="block py-2 px-3 text-white rounded hover:bg-neutral-800 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0" :class="{ 'active': $route.path === '/pricing/' }">Pricing</a>
        </li>
        <li>
          <a href="/solutions/" class="block py-2 px-3 text-white rounded hover:bg-neutral-800 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0" :class="{ 'active': $route.path === '/solutions/' }">Solutions</a>
        </li>
        <li>
          <a href="/application/" class="block py-2 px-3 text-white rounded hover:bg-neutral-800 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0" :class="{ 'active': $route.path === '/application/' }">Application</a>
        </li>
        <li>
          <a href="/blog/" class="block py-2 px-3 text-white rounded hover:bg-neutral-800 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0" :class="{ 'active': $route.path === '/blog/' }">Blog</a>
        </li>
        <li>
          <a href="/contacts/" class="block py-2 px-3 text-white rounded hover:bg-neutral-800 md:hover:bg-transparent md:border-0 md:hover:text-lime-500 md:p-0" :class="{ 'active': $route.path === '/contacts/' }">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<section class="bg-black">
    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-40 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">{{ pageHeading }}</h1>
            <p class="max-w-2xl mb-6 font-light text-gray-400 lg:mb-8 md:text-lg lg:text-xl">{{pageSubheading}}</p>
            <a href="/pricing/" class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-lime-500 rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 hover:-rotate-12">
                See Pricing
                <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
            <a href="/contacts/" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white border border-gray-300 rounded-lg hover:bg-neutral-800 focus:ring-4 focus:ring-gray-100 hover:border-lime-500">
                Speak to Sales
            </a> 
        </div>
        <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="/images/banner-truck.png" alt="mockup">
        </div>                
    </div>
</section>
    </header>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isShopActive: false,
        isConnectorActive: false,
        activeShopPages: ['/eld-device/', '/dash-camera/', '/gps-tracker/', '/obdii-cable/', '/8pin-cable/', '/16pin-cable/'],
        activeConnectorPages: ['/obdii-cable/', '/8pin-cable/', '/16pin-cable/'],
      };
    },
    watch: {
      '$route.path': {
        handler(newValue) {
          this.isShopActive = this.activeShopPages.some(path => newValue.startsWith(path));
          this.isConnectorActive = this.activeConnectorPages.some(path => newValue.startsWith(path));
        },
        immediate: true,
      },
    },
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    computed: {
      pageHeading() {
        return this.$route.meta.h1 || 'Default Heading';
      },
      pageSubheading() {
        return this.$route.meta.subheading || 'Default Subheading';
      },
    },
  }
</script>

  
  <style scoped>
  .active{
    @apply md:text-lime-500
  }
  </style>
  