<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Innovative Tail Light GPS Tracking</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Unlock advanced tracking and security for your truck fleet with our Tail Light GPS Trackers.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">Stealthy, Reliable Tracking</h3>
				<p class="mt-3 text-base leading-relaxed">In the constantly evolving landscape of truck fleet management, the importance of robust and discreet tracking solutions cannot be overstated. Our Tail Light GPS Trackers offer an innovative approach to fleet monitoring and security. Unlike traditional GPS devices, these trackers are ingeniously integrated into the tail lights of trucks, making them virtually undetectable and tamper-proof.<br><br>This stealthy design provides numerous advantages, particularly in preventing theft and unauthorized use of vehicles. In the event of a vehicle being stolen, the covert nature of the tracker allows for real-time location tracking without alerting the thief. This increases the chances of recovering the vehicle swiftly and safely.<br><br>The trackers are engineered for durability and long-term performance, ensuring consistent operation even in harsh weather conditions and challenging environments. They are equipped with advanced GPS technology that provides precise location data, allowing fleet managers to monitor their vehicles' movements with exceptional accuracy.<br><br>Additionally, the trackers are designed for easy installation and maintenance. They seamlessly integrate with existing electrical systems, minimizing downtime and operational disruptions. With their low-profile design, they do not interfere with the vehicle's aesthetics or functionality.<br><br>Our Tail Light GPS Trackers are more than just a theft prevention tool. They are a comprehensive solution for enhancing the overall security and efficiency of your truck fleet, providing peace of mind and a competitive edge in fleet management.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="Stealthy, Reliable Tracking" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Advanced Data Insights</h3>
					<p class="mt-3 text-base leading-relaxed">Beyond location tracking, our Tail Light GPS Trackers are a treasure trove of data, offering deep insights into fleet operations. They collect a wide array of information, including travel routes, stop durations, and speed patterns. This data is crucial for optimizing routes, improving delivery times, and reducing fuel consumption.<br><br>The trackers also facilitate geofencing capabilities. Fleet managers can set up virtual boundaries and receive instant alerts if a vehicle enters or leaves a predefined area. This feature is invaluable for ensuring adherence to designated routes and identifying deviations that could indicate inefficiencies or unauthorized activities.<br><br>Our user-friendly software platform allows for effortless data analysis and report generation. Fleet managers can customize dashboards to monitor specific metrics that are most relevant to their operational goals. Whether it's tracking fuel efficiency, driver behavior, or vehicle utilization, the insights provided by our trackers enable data-driven decision-making.<br><br>Furthermore, the integration of our Tail Light GPS Trackers with other fleet management systems enhances the overall functionality. This integration allows for a holistic view of fleet operations, enabling managers to identify trends, forecast needs, and implement strategic improvements.<br><br>In summary, our Tail Light GPS Trackers are not just tracking devices; they are a powerful tool for optimizing fleet management, enhancing operational efficiency, and driving business growth.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Advanced Data Insights" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Features Of Tail Light Gps Tracker</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Experience unparalleled fleet tracking and management benefits with our innovative Tail Light GPS Trackers.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Covert Theft Prevention</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Accurate Real-Time Tracking</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Easy Installation and Maintenance</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Geofencing for Secure Routes</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Data-Rich Fleet Insights</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Seamless System Integration</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  