<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Empowering Drivers with Training & Support</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Enhance your fleet's performance with our comprehensive driver training and support programs.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">Advanced Training Techniques</h3>
				<p class="mt-3 text-base leading-relaxed">Our "Driver Training and Support" program is meticulously designed to address the evolving needs of truck fleet management. We understand that drivers are the backbone of any fleet operation, and their skills and knowledge play a pivotal role in ensuring safety and efficiency on the road. Our training modules are crafted not just to educate but also to empower drivers, equipping them with the latest insights and practices in the industry.<br><br>The training covers a wide range of topics, from basic vehicle operations to advanced safety protocols. We utilize interactive methods, including virtual reality simulations, to provide hands-on experience in a controlled environment. This approach helps drivers to practice and hone their skills, including navigating challenging road conditions and handling emergency situations, without any real-world risks.<br><br>Our program also focuses on fuel-efficient driving techniques, teaching drivers how to maximize fuel economy through optimal driving practices. This is not only environmentally responsible but also economically beneficial for fleet operations.<br><br>In addition to practical skills, our training encompasses critical soft skills such as communication, stress management, and teamwork. These skills are essential for fostering a positive work environment and ensuring smooth coordination within the fleet.<br><br>Our support system extends beyond the training sessions. We provide continuous learning opportunities through online resources, webinars, and refresher courses, ensuring that drivers stay updated with the latest industry developments and regulations.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="Advanced Training Techniques" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Comprehensive Support System</h3>
					<p class="mt-3 text-base leading-relaxed">The support aspect of our program is designed to provide drivers with ongoing assistance and resources. Our dedicated support team is available to address any queries or concerns drivers may have, whether related to vehicle operation, safety procedures, or regulatory compliance. This continuous support ensures that drivers feel confident and well-informed at all times.<br><br>We also offer a mentoring system, pairing less experienced drivers with seasoned veterans. This peer-to-peer learning fosters a collaborative environment and accelerates the skill development of new drivers.<br><br>Additionally, our program includes regular health and wellness sessions, recognizing the importance of physical and mental well-being in driver performance. These sessions cover topics like ergonomic driving practices, stress reduction techniques, and healthy lifestyle choices.<br><br>To ensure that our training and support are effective, we employ regular assessments and feedback mechanisms. These help us to continually refine our program and tailor it to meet the specific needs of individual drivers and fleets.<br><br>By investing in our Driver Training and Support program, fleet operators can expect not just skilled drivers, but a safer, more efficient, and more cohesive fleet operation.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Comprehensive Support System" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Benefits of Driver Training Programs</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Discover how our specialized driver training and support enhances overall fleet efficiency and safety.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Advanced Driving Skill Development</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Lower Accident Rates</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Increased Driver Retention</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Better Compliance with Regulations</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Enhanced Driver Morale and Satisfaction</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Optimized Route and Fuel Management</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  