<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Advanced Temperature Monitoring for Fleets</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Ensure the integrity of temperature-sensitive cargo with our sophisticated truck fleet temperature monitoring solutions.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">Reliable Cargo Temperature Control</h3>
				<p class="mt-3 text-base leading-relaxed">Effective temperature monitoring is vital for truck fleets carrying perishable goods. Our Temperature Monitoring Solutions offer state-of-the-art technology to ensure the safe transport of temperature-sensitive cargo. These solutions provide real-time temperature data, enabling fleet managers to maintain optimal conditions throughout the transportation process.<br><br>Our system utilizes advanced sensors placed within cargo areas to accurately monitor and record temperature levels. This data is transmitted in real-time to fleet managers, who can view it through a centralized dashboard. The system is capable of alerting managers immediately if temperatures deviate from the set range, allowing for prompt action to prevent spoilage or damage to the cargo.<br><br>Beyond real-time monitoring, our solutions include a comprehensive logging feature. This ensures a detailed temperature history for each journey, which is crucial for compliance with health and safety regulations, and can be invaluable in the event of an insurance claim or customer dispute.<br><br>Our temperature monitoring technology is designed to be robust and reliable, ensuring accuracy even in the most challenging conditions. It is suitable for a range of temperature-sensitive goods, from food and beverages to pharmaceuticals and chemicals.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="Reliable Cargo Temperature Control" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Enhanced Efficiency and Compliance</h3>
					<p class="mt-3 text-base leading-relaxed">The benefits of our Temperature Monitoring Solutions extend beyond safeguarding cargo. They play a key role in enhancing overall operational efficiency and ensuring regulatory compliance. By maintaining optimal cargo temperatures, our system helps reduce waste and associated costs, thus improving the profitability of your fleet operations.<br><br>Our solutions also facilitate compliance with increasingly stringent industry regulations related to the transport of perishable goods. By providing accurate and reliable temperature data, our system ensures that your fleet meets the necessary health and safety standards, avoiding potential fines and reputational damage.<br><br>Additionally, the system offers features such as customizable reports and analytics. These tools allow fleet managers to analyze temperature data over time, identifying trends and making informed decisions to optimize fleet operations. This could involve route adjustments, timing changes, or equipment upgrades.<br><br>Our temperature monitoring solutions are designed with flexibility in mind, easily integrating with existing fleet management systems. This seamless integration allows for a holistic approach to fleet management, where temperature control becomes a part of the broader operational strategy.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Enhanced Efficiency and Compliance" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Benefits of Temperature Monitoring</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Explore how our temperature monitoring solutions enhance cargo safety and compliance in your truck fleet.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Precise Temperature Regulation</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Reduced Cargo Spoilage Risk</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Real-Time Temperature Alerts</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Compliance with Safety Standards</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Detailed Temperature Reporting</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Operational Cost Efficiency</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  