<template>
  <div>
    <SiteHeader />
    <section class="bg-white">
  <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="max-w-screen-md mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">What You'll Gain from Using Truck Fleet Management</h2>
          <p class="text-gray-500 sm:text-xl">Below, you can familiarize yourself with the key advantages of using truck fleet solutions.</p>
      </div>
      <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                  <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
              </div>
              <h3 class="mb-2 text-xl font-bold">Efficiency Enhancement</h3>
              <p class="text-gray-500">Streamlining processes and improving efficiency. Our ELD services pave the way for operational optimization, ensuring your business experiences consistent growth.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                  <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"></path></svg>
              </div>
              <h3 class="mb-2 text-xl font-bold">Regulatory Compliance</h3>
              <p class="text-gray-500">Achieving outstanding results in regulatory compliance. Attain full compliance and transition to smooth sailing, confidently navigating the complex legal landscape.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                  <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd"></path><path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path></svg>                    
              </div>
              <h3 class="mb-2 text-xl font-bold">Mobile Business Hub</h3>
              <p class="text-gray-500">Transform your transport into a mobile business hub. With our ELD services, efficiently manage your business from anywhere, ensuring your business is always within reach.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                  <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd"></path></svg>
              </div>
              <h3 class="mb-2 text-xl font-bold">Financial Efficiency</h3>
              <p class="text-gray-500">Attaining financial success with our ELD services. Feel the direct impact on your finances, gaining analytics, reducing costs, and improving overall profitability.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                  <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
              </div>
              <h3 class="mb-2 text-xl font-bold">Detailed Reporting</h3>
              <p class="text-gray-500">Unlock comprehensive analytics with our ELD services. The rectangular block of data reveals a clear picture, enabling informed decision-making for every aspect of your operations.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
                  <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
              </div>
              <h3 class="mb-2 text-xl font-bold">Seamless Integration</h3>
              <p class="text-gray-500">Harmonize your operations effortlessly. Our ELD services act as the perfect gear, integrating seamlessly with your existing systems, ensuring a smooth and synchronized workflow.</p>
          </div>
      </div>
  </div>
</section>
<section class="bg-black/5 py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-xl px-4 md:px-6">
    <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
      <div>
        <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
          <img src="/images/tfm.png" loading="lazy" alt="semi truck parking" class="h-full w-full object-cover object-center" />
        </div>
      </div>
      <div class="md:pt-8">
        <p class="text-center font-bold text-lime-500 md:text-left">What Is Truck Fleet Management</p>
        <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">Understanding Truck Fleet Management</h2>
        <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
          Delving into the heart of logistics, truck fleet management is your comprehensive solution for optimizing, overseeing, and revolutionizing the operation of your fleet.<br /><br />
          In essence, it's a strategic approach that involves meticulous route planning, productivity enhancement, and seamless fleet control. It's the compass guiding your fleet towards efficiency, cost savings, and elevated operational standards.
        </p>
        <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">Deciphering Operational Excellenc</h2>
        <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">At its core, truck fleet management is about decoding the intricacies of operations. It's a holistic strategy that involves route optimization, productivity amplification, and the seamless orchestration of your fleet.<br><br>
        Uncover the power to reshape logistics, ensuring punctual deliveries, fuel efficiency, and overall resource optimization. Take the helm of your fleet with tools crafted to elevate your business experience.</p>
      </div>
    </div>
  </div>
</section>
<section class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-xl px-4 md:px-8">
    <div class="mb-10 md:mb-16">
      <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Our Fleet by the Numbers</h2>
      <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">Explore the key statistics that define our commitment to efficient truck fleet management.</p>
    </div>
    <div class="grid grid-cols-2 gap-8 md:grid-cols-4 md:gap-0 md:divide-x">
      <div class="flex flex-col items-center md:p-4">
        <div class="text-xl font-bold text-lime-500 sm:text-2xl md:text-3xl">200+</div>
        <div class="text-sm font-semibold sm:text-base">Trucks in Fleet</div>
      </div>
      <div class="flex flex-col items-center md:p-4">
        <div class="text-xl font-bold text-lime-500 sm:text-2xl md:text-3xl">500+</div>
        <div class="text-sm font-semibold sm:text-base">Happy Drivers</div>
      </div>
      <div class="flex flex-col items-center md:p-4">
        <div class="text-xl font-bold text-lime-500 sm:text-2xl md:text-3xl">1000+</div>
        <div class="text-sm font-semibold sm:text-base">Satisfied Customers</div>
      </div>
      <div class="flex flex-col items-center md:p-4">
        <div class="text-xl font-bold text-lime-500 sm:text-2xl md:text-3xl">24/7</div>
        <div class="text-sm font-semibold sm:text-base">Operations</div>
      </div>
    </div>
  </div>
</section>
<section class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="flex flex-col items-center justify-between gap-4 rounded-lg bg-black/5 p-4 sm:flex-row md:p-8">
      <div>
        <h2 class="text-xl font-bold text-lime-500 md:text-2xl">Start your ELD App free trial now!</h2>
        <p class="text-gray-600">Download our app and try the free trial</p>
      </div>
      <a href="/application/" class="inline-block rounded-lg bg-lime-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-lime-600 focus-visible:ring active:bg-lime-600 md:text-base">Start now</a>
    </div>
  </div>
</section>
<section class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="mb-10 md:mb-16">
      <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Frequently asked questions</h2>
      <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">If you haven't found the answer to your question, feel free to reach out to us, and we'll be happy to assist you!</p>
    </div>
    <div class="grid gap-8 sm:grid-cols-2 sm:gap-y-10 xl:grid-cols-3">
      <div class="relative rounded-lg bg-black/5 p-5 pt-8">
        <span class="absolute -top-4 left-4 inline-flex h-8 w-8 items-center justify-center rounded-full bg-lime-500 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </span>
        <h3 class="mb-3 text-lg font-semibold text-lime-500 md:text-xl">How can a truck fleet management system improve driver performance and safety?</h3>
        <p class="text-gray-500">Our system provides insights into driver behavior, promotes safe driving practices, and offers real-time alerts for potential safety issues.</p>
      </div>
      <div class="relative rounded-lg bg-black/5 p-5 pt-8">
        <span class="absolute -top-4 left-4 inline-flex h-8 w-8 items-center justify-center rounded-full bg-lime-500 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </span>
        <h3 class="mb-3 text-lg font-semibold text-lime-500 md:text-xl">How does a truck fleet management system enhance overall fleet efficiency?</h3>
        <p class="text-gray-500">Our system optimizes route planning, monitors vehicle health, and improves communication, leading to enhanced overall fleet efficiency.</p>
      </div>
      <div class="relative rounded-lg bg-black/5 p-5 pt-8">
        <span class="absolute -top-4 left-4 inline-flex h-8 w-8 items-center justify-center rounded-full bg-lime-500 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </span>
        <h3 class="mb-3 text-lg font-semibold text-lime-500 md:text-xl">Can your truck fleet management system assist with compliance to industry regulations?</h3>
        <p class="text-gray-500">Yes, our system ensures compliance with regulations by accurately tracking and managing driver hours, vehicle inspections, and other regulatory requirements.</p>
      </div>
      <div class="relative rounded-lg bg-black/5 p-5 pt-8">
        <span class="absolute -top-4 left-4 inline-flex h-8 w-8 items-center justify-center rounded-full bg-lime-500 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </span>
        <h3 class="mb-3 text-lg font-semibold text-lime-500 md:text-xl">How does the system contribute to cost savings in terms of fuel and maintenance?</h3>
        <p class="text-gray-500">Our system offers route optimization, idle time tracking, and maintenance scheduling, all contributing to significant cost savings in fuel and maintenance.</p>
      </div>
      <div class="relative rounded-lg bg-black/5 p-5 pt-8">
        <span class="absolute -top-4 left-4 inline-flex h-8 w-8 items-center justify-center rounded-full bg-lime-500 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </span>
        <h3 class="mb-3 text-lg font-semibold text-lime-500 md:text-xl">Is it possible to customize alerts and notifications based on specific fleet needs?</h3>
        <p class="text-gray-500">Yes, our system allows you to set up personalized alerts and notifications tailored to the specific requirements of your fleet operations.</p>
      </div>
      <div class="relative rounded-lg bg-black/5 p-5 pt-8">
        <span class="absolute -top-4 left-4 inline-flex h-8 w-8 items-center justify-center rounded-full bg-lime-500 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </span>
        <h3 class="mb-3 text-lg font-semibold text-lime-500 md:text-xl">Can the truck fleet management integrate with existing software used by my business?</h3>
        <p class="text-gray-500">Yes, our system is designed to be compatible with various third-party software, facilitating seamless integration into your existing business workflow.</p>
      </div>
      </div>
  </div>
</section>
</div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  