<template>
  <div>
    <SiteHeader />
<section class="text-gray-600">
	<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 class="text-3xl font-bold text-center sm:text-5xl">Revolutionizing Safety with Dash Cams</h2>
			<p class="max-w-3xl mx-auto mt-4 text-xl text-center">Discover how our Dash Cams and Safety Solutions elevate the security and efficiency of your truck fleet.</p>
		</div>
		<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 class="text-2xl font-bold sm:text-3xl">Cutting-Edge Dash Cam Tech</h3>
				<p class="mt-3 text-base leading-relaxed">In the realm of truck fleet management, the integration of dash cams has become a game-changer for safety and accountability. Our Dash Cams and Safety Solutions are designed not just as passive recording devices but as active safety tools. With high-definition video clarity and wide-angle lens coverage, these dash cams capture a comprehensive view of the road, providing irrefutable evidence in the event of incidents or disputes.<br><br>The advanced features of our dash cams extend beyond mere recording. Equipped with AI-driven analysis, they can detect risky driving behaviors such as sudden braking, rapid acceleration, and close following. This real-time feedback alerts drivers to potential hazards, encouraging safer driving practices. Moreover, in the case of an accident, the dash cams automatically save the footage of the critical moments before and after the impact, ensuring that crucial evidence is preserved.<br><br>Our software integrates seamlessly with these dash cams, providing a user-friendly interface for fleet managers to review and manage footage. This integration allows for efficient monitoring and auditing of driving practices, enhancing overall fleet safety. Additionally, our solution includes cloud storage capabilities, ensuring that the footage is securely backed up and accessible from anywhere.<br><br>With the ever-increasing focus on safety and liability in the trucking industry, having a reliable and sophisticated dash cam system is essential. Our solution not only fulfills this need but also offers advanced features that set new standards in fleet safety and management.</p>
			</div>
			<div aria-hidden="true" class="mt-10 lg:mt-0">
				<img src="/images/solutions/first.png" alt="Cutting-Edge Dash Cam Tech" class="mx-auto rounded-lg shadow-lg">
			</div>
		</div>
		<div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div class="lg:col-start-2">
					<h3 class="text-2xl font-bold sm:text-3xl">Enhanced Driver Safety Tools</h3>
					<p class="mt-3 text-base leading-relaxed">Beyond the dash cams, our Safety Solutions encompass a range of tools specifically designed to enhance driver safety and reduce the risk of accidents. One such innovative feature is our fatigue detection system. Utilizing facial recognition technology, it monitors drivers for signs of fatigue, such as frequent blinking or yawning, and alerts them when it's time to take a break. This proactive approach significantly reduces the risk of accidents caused by driver fatigue.<br><br>Additionally, our solutions include advanced driver assistance systems (ADAS), which offer lane departure warnings, forward collision alerts, and pedestrian detection. These features act as an extra set of eyes on the road, aiding drivers in making safe decisions and reacting promptly to potential hazards.<br><br>Our Safety Solutions also emphasize driver training and development. Through detailed analytics provided by dash cam footage and other sensors, fleet managers can identify areas where drivers may need additional training or coaching. This targeted approach to driver development not only improves safety but also boosts driver confidence and skill.<br><br>Furthermore, our solutions are designed with scalability in mind, ensuring that as your fleet grows, our safety systems can expand to meet your evolving needs. This scalability, coupled with our commitment to continuous innovation, ensures that your fleet is always at the forefront of safety technology.</p>
				</div>
				<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/solutions/second.png" alt="Enhanced Driver Safety Tools" class="mx-auto rounded-lg shadow-lg">
				</div>
			</div>
		</div>
	</div>
</section>
<section class="text-gray-600">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Key Benefits of Dash Cameras And Safety Solutions</h2>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">High-Definition Incident Recording</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">AI-Powered Risky Behavior Detection</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Automated Fatigue Monitoring</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Seamless Cloud Storage Integration</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Real-Time Safety Alerts</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-lime-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Comprehensive Road Coverage Analysis</span>
        </div>
      </div>
    </div>
    <a href="/contacts/" class="flex mx-auto mt-16 text-white bg-lime-500 border-0 py-2 px-8 focus:outline-none hover:bg-lime-600 rounded text-lg w-48 justify-center">Contact us</a>
  </div>
</section>
    </div>
  </template>
  
  <script>
  import SiteHeader from '@/components/SiteHeader.vue';
  
  export default {
    components: {
      SiteHeader,
    },
  };
  </script>
  
  <style scoped>
  </style>
  